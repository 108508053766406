// import React from 'react'
// import './experience.css'
// import {BsShieldFillCheck} from 'react-icons/bs'

// const Experience = () => {
//   return (
//     <section id="experience">
//       <h2>My Experience</h2>
//       <p className="second-heading">What Skills I Have</p>

//       <div className="container experience__container">
//         <div className="experience__frontend">
//           <h3>Frontend Development</h3>
//           <div className="experience__content">
//             <article className="experience__details">
//               <BsShieldFillCheck className="experience__details-icon"/>
//               <div>
//                 <h4>HTML</h4>
//                 <small className="text-light">Experienced</small>
//               </div>
//             </article>
//             <article className="experience__details">
//               <BsShieldFillCheck className="experience__details-icon"/>
//               <div>
//                 <h4>CSS</h4>
//                 <small className="text-light">Experienced</small>
//               </div>
//             </article>
//             <article className="experience__details">
//               <BsShieldFillCheck className="experience__details-icon"/>
//               <div>
//                 <h4>JavaScript</h4>
//                 <small className="text-light">Experienced</small>
//               </div>
//             </article>
//             <article className="experience__details">
//               <BsShieldFillCheck className="experience__details-icon"/>
//               <div>
//                 <h4>React</h4>
//                 <small className="text-light">Experienced</small>
//               </div>
//             </article>
//             <article className="experience__details">
//               <BsShieldFillCheck className="experience__details-icon"/>
//               <div>
//                 <h4>jQuery</h4>
//                 <small className="text-light">Intermediate</small>
//               </div>
//             </article>
//             <article className="experience__details">
//               <BsShieldFillCheck className="experience__details-icon"/>
//               <div>
//                 <h4>Tailwind</h4>
//                 <small className="text-light">Intermediate</small>
//               </div>
//             </article>
//           </div>
//         </div>

//         <div className="experience__backend">
//         <h3>Backend Development</h3>
//           <div className="experience__content">
//             <article className="experience__details">
//               <BsShieldFillCheck className="experience__details-icon"/>
//               <div>
//                 <h4>Node.js</h4>
//                 <small className="text-light">Intermediate</small>
//               </div>
//             </article>
//             <article className="experience__details">
//               <BsShieldFillCheck className="experience__details-icon"/>
//               <div>
//                 <h4>MongoBD</h4>
//                 <small className="text-light">Intermediate</small>
//               </div>
//             </article>
//             <article className="experience__details">
//               <BsShieldFillCheck className="experience__details-icon"/>
//               <div>
//                 <h4>PHP</h4>
//                 <small className="text-light">Intermediate</small>
//               </div>
//             </article>
//             <article className="experience__details">
//               <BsShieldFillCheck className="experience__details-icon"/>
//               <div>
//                 <h4>MySQL</h4>
//                 <small className="text-light">Intermediate</small>
//               </div>
//             </article>
//             <article className="experience__details">
//               <BsShieldFillCheck className="experience__details-icon"/>
//               <div>
//                 <h4>Python</h4>
//                 <small className="text-light">Intermediate</small>
//               </div>
//             </article>
//             <article className="experience__details">
//               <BsShieldFillCheck className="experience__details-icon"/>
//               <div>
//                 <h4>Java</h4>
//                 <small className="text-light">Novice</small>
//               </div>
//             </article>
//           </div>
//           </div>
//       </div>
//     </section>
//   )
// }

// export default Experience

import React from 'react';
import './experience.css';
import { BsShieldFillCheck } from 'react-icons/bs';

const Experience = () => {
  return (
    <section id="experience">
      <h2>My Experience</h2>
      <p className="second-heading">What Skills I Have</p>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsShieldFillCheck className="experience__details-icon" />
              <div>
                <h4>HTML</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsShieldFillCheck className="experience__details-icon" />
              <div>
                <h4>CSS</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsShieldFillCheck className="experience__details-icon" />
              <div>
                <h4>JavaScript</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsShieldFillCheck className="experience__details-icon" />
              <div>
                <h4>React</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsShieldFillCheck className="experience__details-icon" />
              <div>
                <h4>jQuery</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsShieldFillCheck className="experience__details-icon" />
              <div>
                <h4>Next.js</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
          </div>
        </div>

        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsShieldFillCheck className="experience__details-icon" />
              <div>
                <h4>Node.js</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <BsShieldFillCheck className="experience__details-icon" />
              <div>
                <h4>MongoDB</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsShieldFillCheck className="experience__details-icon" />
              <div>
                <h4>PHP</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <BsShieldFillCheck className="experience__details-icon" />
              <div>
                <h4>MySQL</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsShieldFillCheck className="experience__details-icon" />
              <div>
                <h4>Python</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <BsShieldFillCheck className="experience__details-icon" />
              <div>
                <h4>Java</h4>
                <small className="text-light">Novice</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
