import hilltop from './assets/hillTop.webp'
import rcam from './assets/rcam.webp'
import whitetail from './assets/whitetail.webp'
import corey from './assets/coreyWebsite.webp'
import urbnDev from './assets/urbndevelopments.webp'
import urbnStation from './assets/urbnStation1.webp'
import nodeChatterBox from './assets/nodeChatterBox.webp'
import PHPChatterBox from './assets//PHPChatterBox.webp'
import addressBook from './assets/addressBook.webp'
import roadMap from './assets/roadMap.webp'
import jsPizza from './assets/jsPizza.webp'
import jsShuttle from './assets/jsShuttle.webp'
import oldPortfolio from './assets/oldPortfolio.webp'
import pythonATM from './assets/pythonATM.webp'
import cSharpeATM from './assets/cSharpeATM.webp'
import androidNews from './assets/androidNews.webp'
import androidPaint from './assets/androidPaint.webp'
import IOSReminder from './assets/IOSReminder.webp'
import darkBluishBlue from './assets/dark-bluish-blue-2.webp'
import maintenance from './assets/maintenancepng.webp'
import marketing from './assets/marketing-icon.webp'
import responsive from './assets/responsiveDevices.webp'
import webDesign from './assets/web-design-icon.webp'
import cory from './assets/coreyLogo.webp'
import rcamLogo from './assets/r-cam-logo-v5.webp'
import hillTopLogo from './assets/newHillTopLogo.svg'
import flexStudios from './assets/flexstudios.webp'
import fordSalon from './assets/fordSalon.webp'

export const portfolioData = [
  {
    id: 1,
    image: fordSalon,
    title: "Ford Salon",
    description: "Ford Hair Salon Based in Kelowna, BC",
    demo: "https://main.d351mbb7e4jkw8.amplifyapp.com/",
    option: "View Site",
    tech: "REACT ◆ CSS ◆ JavaScript ◆ AWS"
  },
  {
    id: 2, 
    image: urbnDev,
    title: "URBN Developments",
    description: "Digital marketing company website",
    demo: "https://urbndevelopment.ca",
    option: "View Site",
    tech: "HTML ◆ CSS ◆ JavaScript ◆ AWS"
  },
  {
    id: 3, 
    image: whitetail,
    title: "Whitetail Concrete",
    description: "Concrete forming & finishing website.",
    demo: "https://whitetailconcrete.ca",
    option: "View Site",
    tech: "HTML ◆ CSS ◆ JavaScript"
  },
  {
    id: 4, 
    image: rcam,
    title: "R.Cam Contracting",
    description: "Framing & concrete forming website.",
    demo: "https://rcamcontracting.com",
    option: "View Site",
    tech: "HTML ◆ CSS ◆ JavaScript ◆ AWS"
  },
  {
    id: 5, 
    image: hilltop,
    title: "Hill Top Form & Finish",
    description: "Concrete forming & finishing website.",
    demo: "https://hilltopformandfinish.ca",
    option: "View Site",
    tech: "HTML ◆ CSS ◆ JavaScript ◆ AWS"
  },
  {
    id: 6, 
    image: corey,
    title: "Corey Rottenbucher",
    description: "Corey's affiliate marketing website",
    demo: "https://coreyrottenbucher.com",
    option: "View Site",
    tech: "HTML ◆ CSS ◆ JavaScript"
  }
]

export const aFewMore = [
  {
    id: 1, 
    image: darkBluishBlue,
    title: "VS Code Dark Theme",
    description: "Dark theme for VS Code.",
    github: "https://github.com/xlinked/DarkBluishBlue-Theme",
    video: "https://marketplace.visualstudio.com/items?itemName=TylerNelson.DarkBluishBlue",
    option: "Download",
    tech: "Visual Studio Marketplace"
  },
  {
    id: 2, 
    image: urbnStation,
    title: "URBN Station",
    description: "Mens e-commerce website",
    github: "https://github.com/xlinked/URBN-Station",
    video: "https://youtu.be/qt3rEJxeBls",
    option: "Demo",
    tech: "C# ◆ ASP.Net ◆ Bootstrap ◆ MySQL"
  },
  {
    id: 3, 
    image: nodeChatterBox,
    title: 'Chatter Box',
    description: "Social media application.",
    github: "https://github.com/xlinked/Chatter-Box-2-Messaging-application",
    video: "https://youtu.be/xYxowAnO-as",
    option: "Demo",
    tech: "Node ◆ Express ◆ MongoDB ◆ HTML ◆ CSS"
  }
]

export const services = [
  {
    id: 1, 
    image: webDesign, 
    title: 'Web Design',
    description: "Specializing in custom web design, I partner with clients to transform their ideas into digital realities. Whether starting from scratch or revamping an existing site, I ensure each design reflects your unique brand and style, making your website not only visually appealing but also user-friendly."
  },
  {
    id: 2, 
    image: responsive, 
    title: 'Web Development',
    description: "With a deep expertise in front-end and back-end development, I utilize a broad array of programming languages, frameworks, and technologies, including HTML5, CSS3, JavaScript, React, and Node.js. My development process guarantees that your web applications are responsive, fast, and scalable across all devices and platforms."
  }, 
  {
    id: 3, 
    image: maintenance, 
    title: 'Website Maintenance',
    description: "Maintaining a website can be as crucial as building one. I offer comprehensive maintenance services including content updates, hosting migration, custom email template creation, and periodic overhaul of images and logos to keep your site fresh, fast, and reliable."
  },
  {
    id: 4, 
    image: marketing, 
    title: 'Marketing & SEO',
    description: "Boost your website's visibility and traffic with targeted SEO strategies and effective digital marketing campaigns. I provide search engine optimization to improve organic search rankings, along with pay-per-click campaigns on platforms like Google Ads, tailored to bring more visitors and potential customers to your site."
  }
]

export const testimonials = [
  {
    avatar: rcamLogo,
    name: 'R.Cam Contracting', 
    review: "Tyler is an exceptional designer and will do what it takes to make sure your project is top notch. Throughout the project he worked with me to provide awesome designs, took feedback, and provided great customer service. I will definitely work with Tyler again in the future!"
  },
  {
    avatar: hillTopLogo,
    name: 'Hill Top Form & Finish', 
    review: "Tyler is a true professional. He helped us with our website and his services exceeded our expectations. Tyler was very responsive and accessible throughout the process to make sure we were happy with the end result. The Google marketing services he provided has increased our web presence and has helped us generate new business for our company."
  },
  {
    avatar: cory,
    name: 'Cory Rottenbucher', 
    review: "Tyler’s work on my site had me blown away as I clicked through each page he created. Visually gifted, and lots of attention to detail. His work was fast, professional, and his rates are in favour of the client. I will definitely be referring people to him in the future! - Corey Rottenbucher"
  }
]

// Archive page
  export const projects = [
    {
      id: 1,
      image: fordSalon,
      title: "Ford Salon",
      description: "Ford Hair Salon Based in Kelowna, BC",
      demo: "https://main.d351mbb7e4jkw8.amplifyapp.com/",
      option: "View Site",
      tech: "REACT ◆ CSS ◆ JavaScript ◆ AWS"
    },
    {
      id: 2, 
      image: hilltop,
      title: "Hill Top Form & Finish",
      description: "Concrete forming & finishing website.",
      demo: "https://hilltopformandfinish.ca",
      option: "View Site",
      tech: "HTML ◆ CSS ◆ JavaScript ◆ AWS"
    },
    {
      id: 3, 
      image: rcam,
      title: "R.Cam Contracting",
      description: "Framing & concrete forming website.",
      demo: "https://rcamcontracting.com",
      option: "View Site",
      tech: "HTML ◆ CSS ◆ JavaScript ◆ AWS"
    }, 
    {
      id: 4, 
      image: whitetail,
      title: "Whitetail Concrete",
      description: "Concrete forming & finishing website.",
      demo: "https://whitetailconcrete.ca",
      option: "View Site",
      tech: "HTML ◆ CSS ◆ JavaScript"
    },
    {
      id: 5, 
      image: corey,
      title: "Corey Rottenbucher",
      description: "Corey's affiliate marketing website",
      demo: "https://coreyrottenbucher.com",
      option: "View Site",
      tech: "HTML ◆ CSS ◆ JavaScript"
    },
    {
      id: 6, 
      image: urbnDev,
      title: "URBN Developments",
      description: "Digital marketing company website",
      demo: "https://urbndevelopment.ca",
      option: "View Site",
      tech: "HTML ◆ CSS ◆ JavaScript ◆ AWS"
    }, 
    {
      id: 7, 
      image: flexStudios,
      title: "FLEX Studios",
      description: "FLEX Studios gym template website",
      demo: "http://flexstudios.ca.s3-website.us-east-2.amazonaws.com",
      option: "View Site",
      tech: "REACT ◆ CSS ◆ JavaScript ◆ AWS"
    },
    {
      id: 8, 
      image: roadMap,
      title: "RoadMap",
      description: "My software development road map",
      demo: "http://roadmap.ca.s3-website.ca-central-1.amazonaws.com",
      option: "View Site",
      tech: "HTML ◆ CSS ◆ jQuery"
    },
    {
      id: 9, 
      image: jsShuttle,
      title: "JS Shuttle Service",
      description: "Airport shuttle booking website",
      demo: "",
      option: "N/A",
      tech: "HTML ◆ CSS ◆ JavaScript"
    },
    {
      id: 10, 
      image: oldPortfolio,
      title: "Old Portfolio",
      description: "One of my old portfolio websites",
      demo: "http://portfolio.ca.s3-website.ca-central-1.amazonaws.com",
      option: "View Site",
      tech: "HTML ◆ CSS ◆ JavaScript ◆ AWS"
    },
    {
      id: 11, 
      image: jsPizza,
      title: "JS Pizza",
      description: "Pizza ordering website",
      demo: "",
      option: "N/A",
      tech: "HTML ◆ CSS ◆ JavaScript"
    }
  ]
  
  
  
  export const fullStack = [
    {
      id: 1, 
      image: PHPChatterBox,
      title: 'ChatterBox',
      description: "Online messaging application that also allows friends to send private messages.",
      github: "https://github.com/xlinked/PHP-Chat-App-ChatterBox",
      video: "https://youtu.be/X1mImjektME",
      tech: "C# ◆ ASP.Net ◆ Bootstrap ◆ MySQL"
    },
    {
      id: 2, 
      image: addressBook,
      title: "Address Book",
      description: "Address Book is an application for keeping all of your contacts in one place.",
      github: "https://github.com/xlinked/Address-Book",
      video: "https://youtu.be/H4HMn4_jtyI",
      tech: "C# ◆ ASP.Net ◆ Bootstrap ◆ MySQL"
    },
    {
      id: 3, 
      image: nodeChatterBox,
      title: "Node.js Chatter Box",
      description: "Social media application that also allows friends to send private messages.",
      github: "https://github.com/xlinked/Chatter-Box-2-Messaging-application",
      video: "https://youtu.be/xYxowAnO-as",
      tech: "Node ◆ Express ◆ MongoDB ◆ HTML ◆ CSS"
    }, 
    {
      id: 4, 
      image: urbnStation,
      title: "URBN Station",
      description: "Mens e-commerce website",
      github: "https://github.com/xlinked/URBN-Station",
      video: "https://youtu.be/qt3rEJxeBls",
      tech: "C# ◆ ASP.Net ◆ Bootstrap ◆ MySQL"
    },
    {
      id: 5, 
      image: pythonATM,
      title: 'Python ATM Simulator',
      description: "ATM simulator application",
      github: "https://github.com/xlinked/Python-ATM-Simulator",
      video: "https://youtu.be/o_LSWM1Q-EM",
      tech: "Python ◆ tKinter ◆ txt"
    },
    {
      id: 6,
      image: cSharpeATM,
      title: 'C# ATM Simulator',
      description: "ATM simulator application",
      github: "https://github.com/xlinked/ATM-Simulator",
      video: "https://youtu.be/YttMxM02UuE",
      tech: "C# ◆ MySQL"
    }
  ]
  
  
  
  export const apps = [
    {
      id: 1, 
      image: androidNews,
      title: "Android News",
      description: "Mobile application that displays news stories from around the world.",
      github: "https://github.com/xlinked/Android-Java-News-App",
      video: "https://youtu.be/BF2m6CjJzS4",
      tech: 'Java ◆ Android Studio'
    },
    {
      id: 2, 
      image: androidPaint,
      title: "Android Paint",
      description: "Mobile paint application that lets the user draw and paint pictures.",
      github: "https://github.com/xlinked/AndroidPaint.git",
      video: "https://youtu.be/H937HX18yp4",
      tech: 'Java ◆ Android Studio'
    }, 
    {
      id: 3, 
      image: IOSReminder,
      title: "IOS Reminder App",
      description: "Mobile IOS reminder application that let the user create reminders.",
      video: "https://youtu.be/caG7Lsgz3aU",
      github: "https://github.com/xlinked/IOSScheduleApp.git",
      tech: "Swift ◆ Xcode"
    }
  ]