import './otherProjects.css'
import {Link} from 'react-router-dom'
import {aFewMore} from '../../data'

const OtherProjects  = () => {
  
  return (
    <section id="otherProjects">
      <h2>A Few Other Projects</h2>
      <Link to="/pages/Archive/" className="btn" id="otherProjects-link">
        View Archive
      </Link>
      <div className="container otherProjects__container">
      {
        aFewMore.map(({id, image, title, description, github, video, option, tech}) => {
            return (
              <article key={id}  className="otherProjects__item">
                <div className="archive__item-image">
                  <img src={image} alt="{title}" />
                </div>
                <h3>{title}</h3>
                <p>{description}</p>
                <div className="otherProjects__item-cta">
                  <a href={github} className="btn" target="_blank" rel="noreferrer">GitHub</a>
                  <a href={video} className="btn" target="_blank" rel="noreferrer">{option}</a>                </div>
                <small className="other__projects-tech">{tech}</small>
              </article>
            )
          })
        }
        </div>
    </section>    
  )
}
export default OtherProjects