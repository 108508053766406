import React from 'react'
import './about.css'
import ME from '../../assets/IMG_6101NoBackground.webp'
import {FiAward} from 'react-icons/fi'
import {FiUsers} from 'react-icons/fi'
import {AiOutlineFolderOpen} from 'react-icons/ai'
import $ from 'jquery'
// eslint-disable-next-line
{
  // Fade in on scroll.
  $(document).on("scroll", function() {
    let pageTop = $(document).scrollTop();
    let pageBottom = pageTop + $(window).height();
    
    let about__card = $(".about__card");
    for (let i = 0; i < about__card.length; i++) {
      let card = about__card[i];
    
      if ($(card).position().top < pageBottom) {
          $(card).addClass("visible");
      } else {
          $(card).removeClass("visible");
      }
    }
    let about__card_p = $(".about__card-p");
    for (let i = 0; i < about__card_p.length; i++) {
      let card_p = about__card_p[i];
    
      if ($(card_p).position().top < pageBottom) {
          $(card_p).addClass("visible");
      } else {
          $(card_p).removeClass("visible");
      }
    }
    let about__card_btn = $("#about__card-btn");
    for (let i = 0; i < about__card_btn.length; i++) {
      let card_btn = about__card_btn[i];
    
      if ($(card_btn).position().top < pageBottom) {
          $(card_btn).addClass("visible");
      } else {
          $(card_btn).removeClass("visible");
      }
    }
    let about__me_img = $(".about__me_img");
    for (let i = 0; i < about__me_img.length; i++) {
      let about_img = about__me_img[i];
    
      if ($(about_img).position().top < pageBottom) {
          $(about_img).addClass("visible");
      } else {
          $(about_img).removeClass("visible");
      }
    }
});
}



const About = () => {
  return (
    <section id="about">
      <h2>About Me</h2>
      <p className="second-heading">Get To Know Me</p>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me_img">
            <img src={ME} alt="Portrait of Tyler" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FiAward className="about__icon"/>
              <p className="card-header">Experience</p>
              <small>2+<br/>Years</small>
            </article>
            <article className="about__card">
              <FiUsers className="about__icon"/>
              <p>Clients</p>
              <small>10+<br/>Canada-wide</small>
            </article>
            <article className="about__card">
              <AiOutlineFolderOpen className="about__icon"/>
              <p>Projects</p>
              <small>30+<br/>Completed</small>
            </article>
          </div>
          <p className="about__card-p">I love helping awesome people build amazing, responsive 
          yet accessible web applications. I enjoy everything from interaction design to scalable 
          design systems, single-page applications to something more experimental.</p>
          <a href="#contact" id="about__card-btn" className="btn btn-primary">Let's Chat</a>
        </div>
      </div>
    </section>
  )
}

export default About
