import React from 'react'
import Home from '../pages/Home/Home'
import Archive from '../pages/Archive/Archive'
import {Routes, Route, useLocation} from 'react-router-dom'
import {AnimatePresence} from 'framer-motion'

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home/>}/> 
        <Route path="/pages/Archive" element={<Archive/>}/>
      </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes