import ScrollToTop from '../../components/scrollToTop'
import ArchiveHeader from '../../components/archiveHeader/ArchiveHeader'
import ArchiveComp from '../../components/archive/Archive'
import ArchiveFooter from '../../components/archiveFooter/ArchiveFooter'

const Archive = () => {
  return (    
    <>
      <ScrollToTop />
      <ArchiveHeader />
      <ArchiveComp />
      <ArchiveFooter />
    </>
  )
}

export default Archive