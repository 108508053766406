import React from 'react'
import {FiFacebook} from 'react-icons/fi'
import {FiInstagram} from 'react-icons/fi'
import {FiLinkedin} from 'react-icons/fi'
import {FiGithub} from 'react-icons/fi'

const headerSocials = () => {
  return (
    <div className="header__socials">
        <a href="https://www.facebook.com/tyler.nelson.393950/" target="_blank" rel="noreferrer"><FiFacebook/></a>
        <a href="https://www.instagram.com/urbn_innovative_developments/" target="_blank" rel="noreferrer"><FiInstagram/></a>
        <a href="https://www.linkedin.com/in/tyler-nelson-a77846217/" target="_blank" rel="noreferrer"><FiLinkedin/></a>
        <a href="https://github.com/xlinked" target="_blank" rel="noreferrer"><FiGithub/></a>
    </div>
  )
}

export default headerSocials
