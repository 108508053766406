import React from 'react'
import './archiveFooter.css'
import footerLogo from '../../assets/myHeaderLogo.webp'
import {FiFacebook} from 'react-icons/fi'
import {FiInstagram} from 'react-icons/fi'
import {FiLinkedin} from 'react-icons/fi'
import {FiGithub} from 'react-icons/fi'
import {FiTwitter} from 'react-icons/fi'
import {FiYoutube} from 'react-icons/fi'


const Footer = () => {
  return (
    <footer className="archive__footer">
      <a href="/" className="archive__footer-footer__logo">
        <img src={footerLogo} alt="TN logo" />
      </a>

      <div className="archive__footer-footer__socials">
        <a href="https://www.facebook.com/tyler.nelson.393950/" target="_blank" rel="noreferrer"><FiFacebook/></a>
        <a href="https://www.instagram.com/urbn_innovative_developments/" target="_blank" rel="noreferrer"><FiInstagram/></a>
        <a href="https://www.linkedin.com/in/tyler-nelson-a77846217/" target="_blank" rel="noreferrer"><FiLinkedin/></a>
        <a href="https://github.com/xlinked" target="_blank" rel="noreferrer"><FiGithub/></a>
        <a href="https://twitter.com/Tyler96774133" target="_blank" rel="noreferrer"><FiTwitter/></a>
        <a href="https://www.youtube.com/channel/UClrhpEFdRJNEVys61S8sNIg" target="_blank" rel="noreferrer"><FiYoutube/></a>
      </div>

      <div className="archive__footer-footer__copyright">
          <small>Tynelson.ca &copy; All Rights Reserved.</small>
        </div>
      <div className="archive__footer-footer__privacy-policy">
        <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>
      </div>
    </footer>
  )
}

export default Footer
