import React from 'react'
import './footer.css'
import footerLogo from '../../assets/mylogo.webp'
import {FiFacebook} from 'react-icons/fi'
import {FiInstagram} from 'react-icons/fi'
import {FiLinkedin} from 'react-icons/fi'
import {FiGithub} from 'react-icons/fi'
import {FiTwitter} from 'react-icons/fi'
import {FiYoutube} from 'react-icons/fi'


const Footer = () => {
  return (
    <footer>
      <a href="/" className="footer__logo">
        <img src={footerLogo} alt="TN Logo" />
      </a>


      <ul className="permalinks">
        <li><a href="/">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.facebook.com/tyler.nelson.393950/" target="_blank" rel="noreferrer" aria-label="link to Tyler's facebook"><FiFacebook/></a>
        <a href="https://www.instagram.com/urbn_innovative_developments/" target="_blank" rel="noreferrer" aria-label="link to Tyler's Instagram"><FiInstagram/></a>
        <a href="https://www.linkedin.com/in/tyler-nelson-a77846217/" target="_blank" rel="noreferrer" aria-label="link to Tyler's LinkedIn"><FiLinkedin/></a>
        <a href="https://github.com/xlinked" target="_blank" rel="noreferrer" aria-label="link to Tyler's GitHub"><FiGithub/></a>
        <a href="https://twitter.com/Tyler96774133" target="_blank" rel="noreferrer" aria-label="link to Tyler's Twitter"><FiTwitter/></a>
        <a href="https://www.youtube.com/channel/UClrhpEFdRJNEVys61S8sNIg" target="_blank" rel="noreferrer" aria-label="link to Tyler's YouTube"><FiYoutube/></a>
      </div>

      <div className="footer__copyright">
          <small>Tynelson.ca &copy; All Rights Reserved.</small>
        </div>
      <div className="footer__privacy-policy">
        <a href="https://policies.google.com/privacy">Privacy Policy</a>
      </div>
    </footer>
  )
}

export default Footer
