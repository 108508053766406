import React from 'react'
import './testimonials.css'
import {testimonials} from '../../data'
// import Swiper core and required modules
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";


const Testimonials = () => {
  return (
    <section id="testimonials">
      <h2>Testimonials</h2>
      <p className="second-heading">Review's From Clients</p>

      <Swiper className="container testimonials__container"
        modules={[Pagination, Navigation]}
        navigation={true}
        loop={true}
        pagination={{ clickable: true }}>
        {
          testimonials.map(({avatar, name, review}, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
                <div className="client__avatar">
                  <img src={avatar} alt="{name}" />
                </div>
                <p className="clients__name">{name}</p>
                <div className="stars">★★★★★</div>
                <small className="clients__review">{review}</small>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials
