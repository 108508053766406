import React from 'react'
import './portfolio.css'
import {portfolioData} from '../../data'


const Portfolio = () => {
  return (
    <section id="portfolio">
      <h2>Portfolio</h2>
      <p className="second-heading">My Recent Work</p>

      <div className="container portfolio__container">
      {
        portfolioData.map(({id, image, title, description, github, video, demo, option, tech}) => {
            return (
              <article key={id}  className="portfolio__item">
                <div className="portfolio-image">
                  <img src={image} alt="{title}" />
                </div>
                <h3>{title}</h3>
                <p>{description}</p>
                <div className="portfolio__item-cta">
                  <a href={demo} className="btn"  target="_blank" rel="noreferrer">{option}</a>                </div>
                <small className="portfolio-tech">{tech}</small>
              </article>
            )
          })
        }
      </div>
    </section>    
  )
}

export default Portfolio