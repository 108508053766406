import './archiveHeader.css'
import CV from '../../assets/CV.pdf'
import MyHeaderLogo from '../../assets/myHeaderLogo.webp'
import {Link} from 'react-router-dom'


const ArchiveHeader = () => {
  return (
    <div className="archive-header">
      <Link to="/" className="logo">
        <img className="archive-header__logo" src={MyHeaderLogo} alt="TN Initials" />
      </Link>
      <div className="header-btn">
        <a href={CV} className="btn" id="btn__download"></a>
        <Link to="/" className="btn btn-primary" id="archive-link">Back
        </Link>
      </div> 
    </div>   
  )
}

export default ArchiveHeader
