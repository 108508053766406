import './archive.css'
import {projects} from '../../data'
import {fullStack} from '../../data'
import {apps} from '../../data'
import { motion } from 'framer-motion'

const Archive = () => {
  return (    
    <motion.section id="archive"
    initial = {{width: 0}}
    animate = {{width: "100%"}}
    exit = {{ x: window.innerWidth, transition:{duration: 0.1} }}
    >
      <div className="container archive__container">
        <div className="archive__title">
          <h1>Archive</h1>
          <h2>Websites</h2>
        </div>

        {
          projects.map(({id, image, title, description, demo, option, tech}) => {
            return (
              <article key={id}  className="archive__item">
                <div className="archive__item-image">
                  <img src={image} alt="{title}" />
                </div>
                <h3>{title}</h3>
                <p>{description}</p>
                <div className="archive__item-cta">
                    <a href={demo} className="btn" target="_blank" rel="noreferrer">{option}</a>
                </div>
                <small className="archive-tech">{tech}</small>
              </article>
            )
          })
        }   
        <div className="archive__title">
          <h2>Full-Stack Applications</h2>
        </div>
        {
          fullStack.map(({id, image, title, description, github, video, tech}) => {
            return (
              <article key={id}  className="archive__item">
                <div className="archive__item-image">
                  <img src={image} alt="{title}" />
                </div>
                <h3>{title}</h3>
                <p>{description}</p>
                <div className="archive__item-cta">
                    <a href={github} className="btn" target="_blank" rel="noreferrer">GitHub</a>
                    <a href={video} className="btn" target="_blank" rel="noreferrer">Demo</a>
                </div>
                <small className="archive-tech">{tech}</small>
              </article>
            )
          })
        }     
        <div className="archive__title">
          <h2>Android & IOS Apps</h2>
        </div>
        {
          apps.map(({id, image, title, description, github, video, tech}) => {
            return (
              <article key={id}  className="archive__item">
                <div className="archive__item-image">
                  <img src={image} alt="{title}" />
                </div>
                <h3>{title}</h3>
                <p id="apps-description">{description}</p>
                <div className="archive__item-cta">
                    <a href={github} className="btn" target="_blank" rel="noreferrer">GitHub</a>
                    <a href={video} className="btn" target="_blank" rel="noreferrer">Demo</a>
                </div>
                <small className="archive-tech">{tech}</small>
              </article>
            )
          })
        }   
      </div>
    </motion.section>      
  )
}

export default Archive