import Header from '../../components/header/Header'
import Nav from '../../components/nav/Nav'
import About from '../../components/about/About'
import Experience from '../../components/experience/Experience'
import Services from '../../components/services/Services'
import Portfolio from '../../components/portfolio/Portfolio'
import OtherProjects from '../../components/otherProjects/OtherProjects'
import Testimonials from '../../components/testimonials/Testimonials'
import Contact from '../../components/contact/Contact'
import Footer from '../../components/footer/Footer'
import { motion } from 'framer-motion'


const Home = () => {
  return (
    <motion.div
      initial = {{width: 0}}
      animate = {{width: "100%"}}
      exit = {{x: window.innerWidth, transition:{duration: 0.1}}}
    >
      <>
        <Header />
        <Nav />
        <About />
        <Experience />
        <Services />
        <Portfolio />
        <OtherProjects />
        <Testimonials />
        <Contact />
        <Footer />
      </>
    </motion.div>
  )
}

export default Home
