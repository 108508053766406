import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'

const contact = () => {
  return (
    <section id="contact">
      <h2>Contact</h2>
      <p className="second-heading">Get In Touch</p>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon"/>
            <p className="contact__option-p">Email</p>
            <a href="mailto:tynelson84@gmail.com">Send A Email</a>
          </article>
          <article className="contact__option">
            <RiMessengerLine className="contact__option-icon"/>
            <p className="contact__option-p">Messenger</p>
            <a href="https://m.me/tyler.nelson.393950/" target="_blank" rel="noreferrer">Send A Message</a>
          </article>
        </div>
        <form action="https://formsubmit.co/e5d950acc7a70101dbf802a714808203" target="_blank" method="POST">
          <input type="text" name="name" placeholder="Name" required />
          <input type="text" name="email" placeholder="Email" required />
          <textarea type="text" name="message" rows="7" placeholder="Message..." required></textarea>
          <button type="submit" className="btn btn-primary">Send</button>
        </form>
      </div>

    </section>
  )
}

export default contact
