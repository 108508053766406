import React from 'react'
import './services.css'
import {services} from '../../data'
import $ from 'jquery'
// eslint-disable-next-line
{
  // Fade in on scroll.
  $(document).on("scroll", function() {
    var pageTop = $(document).scrollTop();
    var pageBottom = pageTop + $(window).height();
    
    var service__item = $(".service__item");
    for (var i = 0; i < service__item.length; i++) {
      var service = service__item[i];
    
      if ($(service).position().top < pageBottom) {
          $(service).addClass("visible");
      } else {
          $(service).removeClass("visible");
      }
    }
  });
}



const Services = () => {
  return (
    <section id="services">
      <h2>Services</h2>
      <p className="second-heading">What I Offer</p>

      <div className="container services__container">
        {
          services.map(({id, image, title, description}) => {
            return (
              <article key={id} className="service__item">
              <div className="service__item-image">
                <img src={image} alt={title} />
              </div>
                <h3>{title}</h3>
              <div className="service__item-description">
                <p>{description}</p>
              </div>
            </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Services
