import React from 'react'
import './header.css'
import CTA from './CTA'
import My_Image from '../../assets/cartoonMe.svg'
import HeaderSocials from './headerSocials'
import MyHeaderLogo from '../../assets/myHeaderLogo.webp'

import {Link} from 'react-router-dom'

const Header = () => {
  return (    
    <header>
      <Link to="/" className="logo">
        <img className="header__logo" src={MyHeaderLogo} alt="Portrait of Tyler" />
      </Link>
      <div className="header-btn">
        <Link to="/pages/Archive"
           className="btn btn-primary" id="archive-link">Archive
        </Link>
      </div>

      <div className="container header__container">
        <h5>Hello I'm,</h5>
        <h1>Tyler Nelson</h1>
        <p className="header-text">I'm a <span className="text-blue">Full-Stack Developer</span> that specializes in <span className="text-blue">Web Design</span> & <span className="text-blue">Development</span>.</p>
        <CTA />

        <div className="my_image">
          <img src={My_Image} alt="Portrait of Tyler" />
          <a href="#contact" className="scroll__down">SCROLL DOWN</a>
          <HeaderSocials />
        </div>
      </div>
    </header>
  )
}

export default Header